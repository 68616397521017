<template>
	<div>
		<!-- Detect Network Speed -->
		<detected-speed @network-type="handleNetworkIdentified" @network-speed="handleNetworkSpeed"></detected-speed>

		<!-- Offline Dialog -->
		<modal-lost-connection :show="isOffline" @close="closeModalLostConnection"></modal-lost-connection>

		<!-- Slow Dialog -->
		<modal-slow-connection :show="isSlowConnection" @close="closeModalSlowConnection"></modal-slow-connection>
	</div>
</template>

<script>
import ModalLostConnection from '@/components/ModalLostConnection.vue';
import ModalSlowConnection from '@/components/ModalSlowConnection.vue';
import DetectedSpeed from 'vue-identify-network';

export default {
  name: 'IdentifyNetwork',
  components: {
    DetectedSpeed,
    ModalLostConnection,
    ModalSlowConnection,
  },
  props: {},
  mounted() {
  },
  data: () => ({
    isOffline: false,
    connectionType: '4g',
    isSlowConnection: false,
  }),
  methods: {
    handleNetworkIdentified(type) {
      this.connectionType = type;
      this.isSlowConnection = this.connectionType === '2g';
    },
    handleNetworkSpeed(speed) {
      this.isOffline = speed === 0;
    },
    closeModalLostConnection() {
      this.isOffline = false;
    },
    closeModalSlowConnection() {
      this.isSlowConnection = false;
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
</style>
