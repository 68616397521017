export default {
  data() {
    return {
      refreshing__: false,
      registration__: null,
      updateExists__: false,
    };
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable__, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing__) return;
      this.refreshing__ = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists__` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable__(event) {
      this.registration__ = event.detail;
      this.updateExists__ = true;

      // Firefox
      if (this.registration && this.registration.waiting) {
        // send message to SW to skip the waiting and activate the new SW
        this.registration__.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },

    // Called when the user accepts the update
    refreshApp__() {
      this.updateExists__ = false;

      setTimeout(() => {
        // Here the actual reload of the page occurs
        window.location.reload();
      }, 1000);

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration__ || !this.registration__.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration__.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
};
