import Vue from 'vue';
import Vuex from 'vuex';

// import createLogger from '../plugins/logger';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import global from './modules/global';
// import moduleAuth from './auth/moduleAuth';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    global,
    // auth: moduleAuth,
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : [],
});
