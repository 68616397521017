// import { limit } from 'stringz';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; // Set the locale to English
import relativeTime from 'dayjs/plugin/relativeTime';

import utc from 'dayjs/plugin/utc';
import enLocale from './locale/en';
import { allExtensions, ROLE_LEVELS } from './statics';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.locale(enLocale);
dayjs.extend(relativeTime);

export const duplicateVar = value => JSON.parse(JSON.stringify(value));

export const timeAgo = (ds) => {
  const date = new Date(ds);
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};
/**
 * Slugify
 * @see https://gist.github.com/mathewbyrne/1280286
 */
export const slugify = (text) => {
  const slugged = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
  // eslint-disable-next-line
		.replace(/[^\w\-]+/g, '') // Remove all non-word chars
  // eslint-disable-next-line
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
  return slugged;
};

/**
 * Build Query String
 * @param params
 * @see https://stackoverflow.com/a/34209399/5627904
 */
export const buildQuery = (params) => {
  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');
  return query;
};

/**
 * Detect Mobile Browser
 * @see https://stackoverflow.com/a/11381730/5627904
 */
export const isMobile = () => {
  let check = false;
  // eslint-disable-next-line
	(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      )
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))
    ) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

/**
 * Detect Mobile and Tab Browser
 * @see https://stackoverflow.com/a/11381730/5627904
 */
export const isMobileOrTablet = () => {
  let check = false;
  // eslint-disable-next-line
	(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      )
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))
    ) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

/**
 * Get YouTube ID from various YouTube URL
 * @author takien
 * @see http://takien.com
 */
export const getYoutubeId = (url) => {
  let ID = '';
  const urlFormatted = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (urlFormatted[2] !== undefined) {
    // eslint-disable-next-line
		ID = urlFormatted[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = urlFormatted;
  }
  return ID;
};

/**
 * Is Valid URL
 * @see https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
 */
export const isValidURL = (str) => {
  const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return pattern.test(str);
};

/**
 * Is Number
 * @description Check if string is contain number only
 */
export const isNumber = (str) => {
  const reg = new RegExp(/^\d+$/);
  const isNumber = reg.test(str);
  return isNumber;
};

/**
 * Delay
 */
export const delay = (function startDelay() {
  let timer = 0;
  const call = function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
  return call;
}());

/**
 * Email Validation
 */
export const isValidEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const triggerEvent = (el, type) => {
  if ('createEvent' in document) {
    // modern browsers, IE9+
    const e = document.createEvent('HTMLEvents');
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  } else {
    // IE 8
    const e = document.createEventObject();
    e.eventType = type;
    el.fireEvent(`on${e.eventType}`, e);
  }
};

export const formatMonth = (month, lang = 'ID') => {
  const months = {
    ID: [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Agu',
      'Sep',
      'Okt',
      'Nov',
      'Des',
    ],
    US: [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  };
  return months[lang][month];
};

export const getAxiosErrorMessage = e => e.response.data.message;
export const getRequestErrors = e => e.response.data.errors || [];

// Create Combinations
export const createCombinations = (data) => {
  const result = data.reduce((a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w))), []));
  return result;
};

/**
 * Convert Image URL into Base64 Data
 *
 * @param url Image URL
 * @param callback Retrieve Base64 as Param
 *
 * @see https://stackoverflow.com/a/50155915/5627904 StackOverflow
 */
export const imageURLtoBase64 = (url, callback) => {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  img.crossOrigin = 'Anonymous';
  // img.setAttribute('crossOrigin', 'Anonymous');
  img.src = url;

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    callback(dataURL);
  };
};

/**
 * Download File URL
 */
export const downloadFile = (url) => {
  const a = document.createElement('A');
  a.href = url;
  a.download = url.substr(url.lastIndexOf('/') + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// nl2br
export const nl2br = (str) => {
  const breakTag = '<br>';
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

// Get Date Range
export const getDateRange = () => {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
  const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  const filterDate = {
    start: startDate,
    end: endDate,
  };

  return filterDate;
};

// Format Time (Seconds) to display as clock format
export const formatTime = (originalTime) => {
  let time = originalTime ? parseInt(originalTime) : 0;
  const isMinus = time < 0;
  const sign = isMinus ? '-' : '';
  if (isMinus) {
    time = Math.abs(time);
  }
  const oneMinute = 60;
  const oneHour = oneMinute * 60;
  let hours = Math.floor(time / oneHour);
  let minutes = Math.floor((time / oneMinute) % 60);
  if (Number.isNaN(minutes)) {
    minutes = 0;
  }
  let seconds = time % 60;
  if (hours > 0 && hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  const displayTime =	hours === 0 || hours === '0' ? `${minutes}:${seconds}` : `${hours}:${minutes}:${seconds}`;
  return `${sign}${displayTime}`;
};

// Convert String to Title Case
export const toTitleCase = (str) => {
  const string = str === null || str === '' ? '-' : str;
  return string.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

export const textSplitter = (str, l) => {
  let content = str;
  const strs = [];
  while (content.length > l) {
    const newlinePosition = content.substring(0, l).lastIndexOf('\n');
    const isGotNewline = newlinePosition !== -1;
    // If there is a newline within the text range, then get the newline position
    let pos = isGotNewline ? newlinePosition : content.substring(0, l).lastIndexOf(' ');
    pos = pos <= 0 ? l : pos;
    strs.push(content.substring(0, pos));
    if (isGotNewline) {
      strs.push('');
    }
    let i = isGotNewline ? content.indexOf('\n', pos) + 1 : content.indexOf(' ', pos) + 1;
    if (i < pos || i > pos + l) i = pos;
    content = content.substring(i);
  }
  strs.push(content);
  return strs;
};

// Filter User
export const filterUser = keyword => (user) => {
  const firstName = user ? user.name.toLowerCase() : '';
  const fullName = `${firstName}`;
  const email = user.email.toLowerCase();
  const isFirstNameMatch = firstName.includes(keyword);
  const isFullNameMatch = fullName.includes(keyword);
  const isEmailMatch = email.includes(keyword);
  const isMatch = isFullNameMatch || isFirstNameMatch || isEmailMatch;
  return isMatch;
};

// Get Alphabet
export const getAlphabet = (index) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return alphabet[index].toUpperCase();
};

// Axios Global Request Handler
export const globalRequestHandler = (config) => {
  const newConfig = config;
  if (config.params) {
    const newParams = config.params;
    const keys = Object.keys(newParams);
    const boolFormatter = (key) => {
      let val = newParams[key];
      if (typeof val === 'boolean') {
        val = val ? 1 : 0;
        newParams[key] = val;
      }
    };
    keys.forEach(boolFormatter);
    newConfig.params = newParams;
  }
  return newConfig;
};

/**
 * Delete Item from Array
 *
 * @param {string} needle Item
 * @param {string[]} haystack Array
 */
export const deleteFromArr = (needle, haystack) => {
  const index = haystack.findIndex(item => item === needle);
  if (index !== -1) {
    haystack.splice(index, 1);
  }
};

/**
 * Get Random Integer between to values
 *
 * @param {Int} minValue Min Value
 * @param {Int} maxValue Max Value
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 */
export const getRandomInt = (minValue, maxValue) => {
  const min = Math.ceil(minValue);
  const max = Math.floor(maxValue);
  return Math.floor(Math.random() * (max - min)) + min;
};

/**
 * URLFY
 */
export const urlify = (text) => {
  let description = '';
  if (text) {
    description = text;
  }
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return description.replace(urlRegex, url => `<a href="${url}" target="blank">${url}</a>`);
};

/**
 * Group By
 */
export const groupBy = (arr, property) => {
  const grouped = arr.reduce((memo, x) => {
    const temp = memo;
    if (!temp[x[property]]) { temp[x[property]] = []; }
    temp[x[property]].push(x);
    return temp;
  }, {});
  return grouped;
};

/**
 * Generate Dates
 */
export const generateDates = (value, type, format = 'YYYY-MM-DD', endDate) => {
  const dates = [];
  const firstIndex = value > 0 ? 0 : value;
  const lastIndex = value > 0 ? value : 0;
  for (let index = firstIndex; index <= lastIndex; index++) {
    const day = endDate || dayjs();
    const date = day.add(index, type).format(format);
    dates.push(date);
  }
  return dates;
};

/**
 * Format Date
 * Reference https://day.js.org/docs/en/display/format
 */
export const dateFormat = (value, format = 'YYYY-MM-DD', timeZone = 'Asia/Jakarta') => {
  const date = dayjs.utc(value).tz(timeZone).format(format);
  return date;
};


/**
 * Scroll to Top
 */
export const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

/**
 * Show Notif
 */
export const showNotif = (title, message, link, icon = 'https://myproject.ajak.in/img/logo-small.png') => {
  if (window.Notification) {
    const { permission } = Notification;
    const options = {
      body: message,
      icon,
    };
    const onClick = (event) => {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      if (link) {
        window.open(link, '_blank');
      }
    };
    if (permission === 'granted') {
      const notification = new Notification(title, options);
      notification.onclick = onClick;
    } else if (permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(title, options);
          notification.onclick = onClick;
        }
      });
    }
  } else {
    // eslint-disable-next-line
		console.log('This browser does not support notification');
  }
};

/**
 * Get Notif Link
 */
export const getNotifLink = (notif) => {
  // let link = process.env.VUE_APP_URL || 'https://myproject.ajak.in';
  let link = '';
  const payload = notif.payload ? JSON.parse(notif.payload) : null;
  if (payload && payload !== '') {
    const type = notif.type;
    if (type === 'order_completed') {
      const projectId = payload.id;
      link += `/projects/${projectId}`;
    } else if (type === 'order_offer') {
      const orderId = payload.id;
      link += `/orders/${orderId}`;
    } else if (type === 'direct_offer') {
      const offerId = payload.offer_id;
      link += `/orders/new?step=4&offer_id=${offerId}`;
    }
  }
  return link;
};

/**
 * Get Video Blob
 */
export const getVideoBlob = async (url) => {
  const promise = new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'blob';

    req.onload = function onLoad() {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (this.status === 200) {
        const videoBlob = this.response;
        resolve(videoBlob);
        // var vid = URL.createObjectURL(videoBlob); // IE10+
        // Video is now downloaded
        // and we can set it as source on the video element
        // video.src = vid;
      }
    };

    req.onerror = function onError() {
      reject(new Error('Error When Downloading Video'));
    };

    req.send();
  });
  return promise;
};

/**
 * Days in month
 */
export const daysInMonth = (month, year) => {
  const days = new Date(year, month, 0).getDate();
  return days;
};

/**
 * Get asset type
 */
export const getAssetType = (url = '') => {
  let type = '';
  const keys = Object.keys(allExtensions);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const extensions = allExtensions[key];
    for (let extIndex = 0; extIndex < extensions.length; extIndex++) {
      const extension = extensions[extIndex];
      const isSame = url ? url.includes(extension) : false;
      if (isSame) {
        type = key;
        break;
      }
    }
  }
  return type;
};

/**
 * Get money format
 */
export const getMoneyFormat = (currency = 'NOK') => {
  const normalCountries = ['IDR', 'NOK'];
  const isNormal = normalCountries.includes(currency);
  const decimal = isNormal ? 0 : 2;
  const decimalSep = isNormal ? ',' : '.';
  const thousandSep = isNormal ? '.' : ',';
  const format = {
    decimal,
    decimalSep,
    thousandSep,
  };
  return format;
};

/**
 * Format number
 */
export const formatNumber = (n, decimals, decimalSep, thousandsSep) => {
  // if decimal is zero we must take it, it means user does not want to show any decimal
  const c = Number.isNaN(decimals) ? 2 : Math.abs(decimals);

  // if no decimal separator is passed we use the dot as default decimal separator
  // (we MUST use a decimal separator)
  const d = decimalSep || '.';

  // if you don't want to use a thousands separator you can pass empty string as thousandsSep value
  const t = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;

  const sign = (n < 0) ? '-' : '';

  // extracting the absolute value of the integer part of the number and converting to string
  let nX = n;
  const i = parseInt(nX = Math.abs(nX).toFixed(c), 10).toString();

  let j = i.length;
  j = (j > 3) ? j % 3 : 0;

  const x = j ? i.substr(0, j) + t : '';
  const y = i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`);
  const z = c ? d + Math.abs(n - i).toFixed(c).slice(2) : '';

  return sign + x + y + z;
};

/**
 * Format number plain
 */
export const formatNumberPlain = (number, currency = 'NOK') => {
  const format = getMoneyFormat(currency);
  const money = formatNumber(number, format.decimal, format.decimalSep, format.thousandSep);
  return money;
};

/**
 * Currency symbols
 */
export const CURRENCY_SYMBOLS = {
  IDR: 'Rp', // Indonesian Rupiah
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  CNY: '¥', // Chinese Yuan
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  NOK: 'kr', // Norwegian Krone
};

/**
 * Get currency symbol
 */
export const getCurrencySymbol = currencyName => CURRENCY_SYMBOLS[currencyName] || currencyName;

/**
 * Format money
 */
export const formatMoney = (numberInput, currency = 'NOK') => {
  const isMinus = numberInput < 0;
  const format = getMoneyFormat(currency);
  const number = isMinus ? numberInput * -1 : numberInput;
  const money = formatNumber(number, format.decimal, format.decimalSep, format.thousandSep);
  const symbol = getCurrencySymbol(currency);
  const formatted = isMinus ? `-${symbol}${money}` : `${symbol}${money}`;
  return formatted;
};

/**
 * Click
 */
export const click = (el, x, y, eventName = 'mousedown') => {
  const ev = new MouseEvent(eventName, {
    view: window,
    bubbles: true,
    cancelable: true,
    screenX: x,
    screenY: y,
    clientX: x,
    clientY: y,
  });

  const element = el || document.elementFromPoint(x, y);
  element.dispatchEvent(ev);
};

/**
 * Relative coords
 */
export const relativeCoords = (el, clientX, clientY) => {
  const bounds = el.getBoundingClientRect();
  const x = clientX - bounds.left;
  const y = clientY - bounds.top;
  return { x, y };
};

/**
 * Decode URI Component safe to use with single %
 *
 * @param {string} s String to Decode
 * @return {string} Decoded Result
 *
 * @see https://stackoverflow.com/a/54310080/5627904
 */
export const decodeURIComponentSafe = (s) => {
  if (!s) return s;
  return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
};

/**
 * Get Param by Name
 */
export const getParamByName = (name, url, isCaseSensitive = false) => {
  const theUrl = url || window.location.href;
  const key = name.replace(/[\[\]]/g, '\\$&');
  const pattern = `[?&]${key}(=([^&#]*)|&|#|$)`;
  const regex = isCaseSensitive ? new RegExp(pattern) : new RegExp(pattern, 'i');
  const results = regex.exec(theUrl);
  if (!results) return null;
  if (!results[2]) return '';
  // const value = decodeURIComponent(results[2].replace(/\+/g, ' '));
  const value = decodeURIComponentSafe(results[2].replace(/\+/g, ' '));
  return value;
};

/**
 * Scroll element to bottom
 */
export const scrollElemToBottom = (id) => {
  const elem = document.getElementById(id);
  if (elem) {
    const scrollHeight = elem.scrollHeight;
    elem.scrollTop = scrollHeight;
  }
};


/**
 * Limit String
 * @see https://www.npmjs.com/package/stringz
 */
// export const limitString = (text, maxLimit) => {
// 	const string = text || '';
// 	const stringLimit = limit(string, maxLimit);
// 	return stringLimit;
// };

// Get Initials
export const getInitials = (string) => {
  const names = string.split(' ');
  const initials = names.map(name => name.charAt(0).toUpperCase());
  if (initials.length > 1) {
    return `${initials[0]}${initials[initials.length - 1]}`;
  }
  return initials[0];
};

// Get Order Groups
export const getOrderGroups = (orderGroups, userSkills, personnels) => {
  const filteredGroups = [];
  for (let index = 0; index < orderGroups.length; index++) {
    const orderGroup = orderGroups[index];
    const requiredPeople = orderGroup.number_of_people;
    const joinedPersonnels = personnels.filter(personnel => personnel.order_group_personnel_id === orderGroup.id);
    const isFull = requiredPeople === joinedPersonnels.length;
    const freelanceRate = orderGroup.freelanceRate;
    if (!isFull && freelanceRate && freelanceRate.skill) {
      const skill = freelanceRate.skill;
      const userSkill = userSkills.find(userSkill => userSkill.skill_id === skill.id);
      if (userSkill) {
        filteredGroups.push(orderGroup);
      }
    }
  }
  return filteredGroups;
};

// Get Earnings
export const getEarnings = (orderGroups, userSkills) => {
  const earnings = [];
  for (let index = 0; index < orderGroups.length; index++) {
    const orderGroup = orderGroups[index];
    const freelanceRate = orderGroup.freelanceRate;
    if (freelanceRate && freelanceRate.skill) {
      const skill = freelanceRate.skill;
      const userSkill = userSkills.find(userSkill => userSkill.skill_id === skill.id);
      if (userSkill) {
        earnings.push(orderGroup.price);
      }
    }
  }
  return earnings;
};

// Get Earnings Label
export const getEarningsLabel = (earnings) => {
  let earningLabel = earnings[0] || 0;
  if (earnings.length > 1) {
    const currency = 'NOK';
    const minEarning = Math.min.apply(null, earnings);
    const min = formatNumberPlain(minEarning, currency);
    const maxEarning = Math.max.apply(null, earnings);
    const max = formatNumberPlain(maxEarning, currency);
    earningLabel = `${min} - ${max}`;
  }
  return earningLabel;
};

// Is Can See
export const isCanSee = (to, router, role) => {
  let isCanSee = false;
  if (to) {
    const routeRule = router.match(to).meta.rule;
    const roleLevel = ROLE_LEVELS[role];
    if (routeRule.includes(roleLevel)) isCanSee = true;
  }
  return isCanSee;
};

// Check is function
export const isFunction = functionToCheck => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

// Download Blob
export const downloadBlob = (contents, config) => {
  const { name = 'contents', type = 'excel' } = config;
  const fileURL = window.URL.createObjectURL(new Blob([contents]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  let extension = 'xlsx';
  if (type === 'docx') {
    extension = 'docx';
  }
  const fileName = `${name}.${extension}`;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
};

// Convert Date to ISO String with local Timezone
export const toISOLocal = (d) => {
  const z = n => (`0${n}`).slice(-2);
  const zz = n => (`00${n}`).slice(-3);
  let off = d.getTimezoneOffset();
  const sign = off < 0 ? '+' : '-';
  off = Math.abs(off);

  return `${d.getFullYear()}-${
    z(d.getMonth() + 1)}-${
    z(d.getDate())}T${
    z(d.getHours())}:${
    z(d.getMinutes())}:${
    z(d.getSeconds())}.${
    zz(d.getMilliseconds())
    // eslint-disable-next-line no-bitwise
  }${sign}${z(off / 60 | 0)}:${z(off % 60)}`;
};

// Generate Menu Items
export const generateMenuItems = (eventSlug) => {
  const items = [
    // Overview
    {
      name: 'Settings',
      i18n: 'Settings',
      iconMdi: 'Settings',
      url: `/events/${eventSlug}/details`,
    },
    // Homepage
    {
      name: 'Event Page',
      i18n: 'Event Page',
      iconMdi: 'Info',
      url: `/events/${eventSlug}/homepage`,
    },
    // Attendees
    {
      name: 'Participant',
      iconMdi: 'Participant',
      i18n: 'Participant',
      url: `/events/${eventSlug}/attendees`,
    },
    // Q & A
    {
      name: 'Q & A',
      i18n: 'Q & A',
      iconMdi: 'Q&A',
      url: `/events/${eventSlug}/qna`,
    },
    // Polls
    {
      name: 'Polls',
      i18n: 'Polls',
      iconMdi: 'Polls',
      url: `/events/${eventSlug}/polls`,
    },
    // Analytics
    {
      name: 'Metrics',
      i18n: 'Metrics',
      iconMdi: 'Analytics',
      url: `/events/${eventSlug}/metrics`,
    },
  ];
  return items;
};

// Get created question time
export const getCreatedQuestionTime = dateTime => dayjs(dateTime || new Date()).fromNow();

export const getFirstCharacter = (str) => {
  if (str && str.length > 0) {
    const firstChar = str.charAt(0);
    return firstChar.toUpperCase();
  }
  return '';
};

export const getAvaColor = (str) => {
  if (str && str.length > 0) {
    return str.charAt(0);
  }
  return '';
};

export const generateColorByAlphabet = (letter) => {
  const colorMap = {
    a: '#DBC60E',
    b: '#E91F63',
    c: '#4185F3',
    d: '#F34236',
    e: '#3AAC37',
    f: '#9B27B0',
    g: '#DBC60E',
    h: '#E91F63',
    i: '#0154C7',
    j: '#F34236',
    k: '#3AAC37',
    l: '#9B27B0',
    m: '#DBC60E',
    n: '#E91F63',
    o: '#0154C7',
    p: '#F34236',
    q: '#3AAC37',
    r: '#9B27B0',
    s: '#0154C7',
    t: '#E91F63',
    u: '#0154C7',
    v: '#F34236',
    w: '#3AAC37',
    x: '#9B27B0',
    y: '#DBC60E',
    z: '#E91F63',
  };
  let lowercaseLetter = '';
  let color = '';
  if (letter && letter.length > 0 && letter !== 'Anonymous') {
    const initial = letter.charAt(0);
    lowercaseLetter = initial.toLowerCase();
    color = colorMap[lowercaseLetter] || '#C3C3C3';
  } else {
    color = '#C3C3C3';
  }
  return color;
};
