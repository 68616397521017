import Vue from 'vue';
import dayjs from 'dayjs';
import VTooltip from 'v-tooltip';
import Notifications from 'vue-notification';
import io from 'socket.io-client';
import VueSocketio from 'vue-socket.io-extended';
import vueXlsxTable from 'vue-xlsx-table';
import jQuery from 'jquery';
import browserDetect from 'vue-browser-detect-plugin';
import 'cropperjs/dist/cropper.css';
// import VueYoutube from 'vue-youtube';
// import utc from 'dayjs/plugin/utc';
// import NProgress from 'nprogress';
// import PerfectScrollbar from 'vue-perfect-scrollbar';

// import 'vue-perfect-scrollbar/dist/vue-perfect-scrollbar.css';
// Vuesax Component Framework
import { toTitleCase } from '@/lib/helper';

import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css';
import 'vuesax/dist/vuesax.css';
import VueClipboard from 'vue-clipboard2';
import VueTour from 'vue-tour';
import VeeValidate from 'vee-validate';
import { VueHammer } from 'vue2-hammer';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import wysiwyg from 'vue-wysiwyg'; // config is optional. more below
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import {
  i18n,
  loadLanguageAsync,
} from './setup/i18n-setup';

// import noLocale from './lib/locale/no';

// Theme Configurations
import '../themeConfig';

// Globally Registered Components
import './globalComponents';

// Styles: SCSS
import '@/assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// editor
Vue.use(wysiwyg, {
  hideModules: { image: true },
});

// Vue.use(PerfectScrollbar);
Vue.component('v-select', vSelect);
Vue.use(Vuesax);
Vue.use(VueClipboard);
Vue.use(VeeValidate);
Vue.use(VueHammer);
Vue.use(browserDetect);
Vue.use(VTooltip);
Vue.use(Notifications);
Vue.use(vueXlsxTable, { rABS: false });
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');
// Vue.use(VueYoutube);

// Feather font icon
require('./assets/css/iconfont.css');

Vue.config.productionTip = false;
window.$ = jQuery;
window.jQuery = jQuery;

// Socket
const isSocketActive = true;
if (isSocketActive) {
  const SOCKET_URL = process.env.VUE_APP_SOCKET_URL || 'http://127.0.0.1:3333';
  const socket = io(SOCKET_URL, {
    transports: ['websocket'],
  });
  Vue.use(VueSocketio, socket, { store });
}

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// Filters
const dateFilter = (dateString, format = 'YYYY-MM-DD HH:mm', isUtc = false) => (isUtc ? dayjs.utc(dateString).format(format) : dayjs(dateString).format(format));
Vue.filter('date', dateFilter);
Vue.filter('title', str => (str ? toTitleCase(str.replace(/_/g, ' ')) : ''));

let currentLang = 'id';

const redirectedRoutes = [];

// Login Middleware
router.beforeEach((to, from, next) => {
  const { path, name, params } = to;
  const { isLoggedIn } = store.getters;

  const errors = [];

  const alreadyLoggedIn = path === '/login' && isLoggedIn;
  if (alreadyLoggedIn) errors.push('already_logged_in');

  const isNotAvailable = errors.length > 0;

  // Allowed Path
  const allowedPaths = [
    '/login',
    '/login-sms',
    '/logout',
    '/register',
    '/forgot-password',
    '/user/verify',
    '/password/reset',
    '/privacy-policy',
    '/upcoming-events',
    '/terms-and-conditions',
    '/auto_login',
    '/error-404',
    '/code=',
  ];

  // Allowed Component Name
  const allowedComponentNames = [
    'EventLive',
    'EventLive',
    'EventLiveEmbed',
    'EventDisplay',
    'EventDisplayClean',
    'EventCancelSeat',
    'EventConfirmSeat',
    'EventCode',
    'EventDisplayWithSidebar',
    'EventDisplayWithoutSidebar',
    'EventDisplayHighlight',
    'EventDisplayQR',
    'EventDisplayControl',
    'EventScanner',
    'Iframe',
  ];

  const isModeratorGuest = localStorage.getItem('is_moderator') === 'true';
  const storedSlug = localStorage.getItem('slug_m_code');
  const routeSlug = params.slug;

  // Restrict moderator guest access
  if (isModeratorGuest && routeSlug && routeSlug !== storedSlug) {
    next(`/events/${storedSlug}/details`); // Redirect to the correct event if the slug doesn't match
  // eslint-disable-next-line brace-style
  }
  // Restrict moderator guest access if not allowed to access
  else if (
    (isModeratorGuest

    && !path.includes('/details')
    && !path.includes('/homepage')
    && !path.includes('/attendees')
    && !path.includes('/qna')
    && !path.includes('/polls')
    && !path.includes('/metrics')
    && !allowedComponentNames.includes(name)
    && !allowedPaths.includes(path))
    || (isModeratorGuest && path === '/error-404')
  ) {
    next(`/events/${storedSlug}/details`); // Redirect moderator guests to the correct event details
  } else if (allowedComponentNames.includes(name)) {
    next();
  } else if (!isLoggedIn && !allowedPaths.includes(path)) {
    let endpoint = '/login';
    if (redirectedRoutes.includes(to.name)) {
      endpoint += `?ref=${path}`;
    }
    next(endpoint);
  } else if (allowedPaths.includes(path)) {
    next();
  } else if (isNotAvailable) {
    next('/');
  } else {
    next();
  }
});

// Change Title
router.beforeEach((to, from, next) => {
  store.dispatch('navigate', { to });
  const title = to.meta.title || to.meta.pageTitle || '';
  document.title = title;
  next();
});

// Lazy load Languages
router.beforeEach((to, from, next) => {
  const lang = to.query.lang || store.getters.locale;
  loadLanguageAsync(lang).then(() => next());
  if (currentLang !== lang) {
    currentLang = lang;
    dayjs.locale(lang);
  }
});

// add directive to sanitize input
Vue.directive('filterInput', {
  bind(el) {
    el.addEventListener('input', (event) => {
      event.target.value = event.target.value;
    });
  },
});

// add directive to sanitize input
Vue.directive('filterInputEmail', {
  bind(el) {
    el.addEventListener('input', (event) => {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9\s.,-_]/g, '');
    });
  },
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
