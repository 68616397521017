<template>
	<div id="app" :class="vueAppClasses">
		<router-view @setAppClasses="setAppClasses" />

		<!-- Network Identify -->
		<identity-network v-if="(activeRouteName !== 'EventDisplay' && activeRouteName !== 'EventDisplayClean')"></identity-network>

    <!-- Update App -->
    <update-app v-if="isLoggedIn && activeRouteName !== 'EventLive' && (activeRouteName !== 'EventDisplay' && activeRouteName !== 'EventDisplayClean')" />
		<!-- Cookie Law -->
		<cookie-law
      v-if="((activeRouteName !== 'EventDisplay' && activeRouteName !== 'EventDisplayClean'))"
			theme="primary"
			:message="$t('This website makes use of cookies to enhance browsing experience and provide additional functionality')"
			:buttonText="$t('Accept Cookies')"
			:storageName="'cookieConsentKobla'"
			:storageType="'localStorage'">
			<div slot-scope="props" class="lg:flex justify-between w-full items-center">
				<p class="mb-4 lg:mb-0 ">
					<span class="font-bold">{{ $t('Your privacy matters.') }}</span>
					{{ $t("We use cookies to improve your experience while using this application, learn more about our") }} <a href="#">{{ $t("Privacy Policy") }}</a>
				</p>
				<div class="flex space-x-4">
					<vs-button color="#656565" @click="rejectCookies(props.close)">{{ $t('Reject Cookies') }}</vs-button>
					<vs-button @click="props.accept">{{ $t('Allow Cookies') }}</vs-button>
				</div>
			</div>
		</cookie-law>

		<!-- Cookie Rejected -->
		<div class="rejected-cookie" v-if="isCookieRejected && (activeRouteName !== 'EventDisplay' && activeRouteName !== 'EventDisplayClean')">
			<div class="relative">
				<div class="vx-row h-screen lg:flex w-full no-gutter justify-center align-center">
					<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/1 sm:m-0 m-4">
						<div class="flex items-center justify-center h-full">
							<div>
								<div class="text-center">
									<img src="@/assets/images/logo/koblatil.png" alt="Kobla" />
								</div>
								<div class="mt-4">
									{{ $t("The application requires cookie to run, for interactive session such as Q&A and Polls.") }}
								</div>
								<div class="mt-8 text-center">
									<vs-button @click="reloadPage()">
										{{ $t('Reload page') }}
									</vs-button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="section-links flex space-x-4">
					<a href="#">{{ $t("Privacy Policy") }}</a>
					<a href="#">{{ $t("Terms and Conditions") }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import themeConfig from '@/../themeConfig';
import CookieLaw from 'vue-cookie-law';
import IdentityNetwork from '@/components/IdentityNetwork.vue';
import UpdateApp from './components/UpdateApp.vue';

export default {
  components: {
    CookieLaw,
    IdentityNetwork,
    UpdateApp,
  },
  data() {
    return {
      vueAppClasses: [],
      isCookieRejected: false,
    };
  },
  watch: {
    '$store.state.theme': function (val) {
      this.toggleClassInBody(val);
    },
    '$vs.rtl': function (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr');
    },
    activeRouteName() {
      this.checkSetToken();
    },
  },
  methods: {
    checkSetToken() {
      const attendeeRoutes = ['EventLive', 'EventProfile'];
      if (attendeeRoutes.includes(this.activeRouteName)) {
        this.$store.dispatch('setTokenAttendeeClient');
      } else {
        this.$store.dispatch('setTokenAdminClient');
      }
    },
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    },
    rejectCookies(closeCookie) {
      closeCookie();

      this.isCookieRejected = true;
    },
    reloadPage() {
      window.location.reload();
    },
  },
  mounted() {
    this.checkSetToken();

    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  async created() {
    const dir = this.$vs.rtl ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
    console.log(!(this.activeRouteName !== 'EventDisplay', this.activeRouteName !== 'EventDisplayClean'));
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
    activeRouteName() {
      return this.$route.name;
    },
  },
};

</script>
