<template>
	<transition name="modal">
		<div>
			<div class="modal-mask" :class="colorMask">
				<div
					class=""
					:class="{ 'has-scroll': hasScroll, 'is-fixed': fixed, 'modal-wrapper': !isMobile }">
					<div
						class="modal-container"
						:class="[`modal-container-${size}`, { 'd-flex flex-column': fixed }]">
						<div class="modal-header" v-if="$slots.header">
							<slot name="header">
								Modal Header
							</slot>
							<div class="modal-close" @click="$emit('close')" v-if="showClose">
								<i class="material-icons">close</i>
							</div>
							<button type="button" class="close hidden" aria-label="Close" @click="$emit('close')">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div
							class="modal-body"
							:class="[{ 'p-0': full }, { 'flex-column-content': fixed }]">
							<slot name="body">
								Modal Body
							</slot>
						</div>

						<div class="modal-footer"  :class="{ 'p-0': footerFull }" v-if="!hideFooter">
							<slot name="footer">
								<button class="btn btn-plain btn-icon" @click="$emit('close')">
									<i class="material-icons va-m text-primary">check</i>
									<span class="va-m">OK</span>
								</button>
							</slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    size: {
      type: String,
      default: 'small',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    footerFull: {
      type: Boolean,
      default: false,
    },
    hasScroll: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    colorMask: {
      type: String,
      default: '',
    },
    isEnableKeyUp: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onKeyup(event) {
      if (event.key === 'Escape') this.$emit('close');
    },
  },
  created() {
    document.body.classList.add('modal-open');
    if (this.isEnableKeyUp) window.addEventListener('keyup', this.onKeyup);
  },
  destroyed() {
    document.body.classList.remove('modal-open');
    document.body.classList.remove('modal-add-question-opened');
    if (this.isEnableKeyUp) window.removeEventListener('keyup', this.onKeyup);
  },
};
</script>

<style lang="scss">
</style>
