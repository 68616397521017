const localeObject = {
  name: 'id', // name String
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'), // weekdays Array
  weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
  weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
  weekStart: 1, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
  months: 'January_February_Marc_April_May_June_July_August_September_October_November_December'.split('_'), // months Array
  monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Des'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
  ordinal: n => `${n}º`, // ordinal Function (number) => return number + output
  formats: {
    // abbreviated format options allowing localization
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
    // lowercase/short, optional formats for localization
    l: 'D/M/YYYY',
    ll: 'D MMM, YYYY',
    lll: 'D MMM, YYYY h:mm A',
    llll: 'ddd, MMM D, YYYY h:mm A',
  },
  relativeTime: {
    // relative time format strings, keep %s %d as the same
    future: 'in %s', // e.g. in 2 hours, %s been replaced with 2hours
    past: '%s ago',
    s: 'second',
    m: 'minute',
    mm: '%d minute',
    h: 'hour',
    hh: '%d hours', // e.g. 2 hours, %d been replaced with 2
    d: 'day',
    dd: '%d days',
    M: 'month',
    MM: '%d months',
    y: 'year',
    yy: '%d years',
  },
  // eslint-disable-next-line
	meridiem: (hour, minute, isLowercase) => {
    // OPTIONAL, AM/PM
    return hour > 12 ? 'PM' : 'AM';
  },
};

export default localeObject;
