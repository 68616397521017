import client from '../lib/http-client';

const endpoint = '/auth';

export default {
  // Login
  login(creds, cb, errorCb) {
    const url = `${endpoint}/login`;
    client.post(url, creds)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // register
  register(params, cb, errorCb) {
    const url = `${endpoint}/register`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  /**
	 * Verify registration
	 *
	 * @param {Object} params Params
	 * @param {string} params.token Token
	 * @param {string} params.lang Lang
	 * @param {*} cb
	 * @param {*} errorCb
	 */
  verifyRegistration(params, cb, errorCb) {
    const url = `${endpoint}/register/verified`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Logout
  logout(cb, errorCb) {
    const url = `${endpoint}/logout`;
    client.post(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Logout Others
  logoutOthers(cb, errorCb) {
    const url = `${endpoint}/logoutOthers`;
    client.post(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Logout All
  logoutAll(cb, errorCb) {
    const url = `${endpoint}/logoutAll`;
    client.post(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Get User Profile
  getProfile(cb, errorCb) {
    const url = `${endpoint}/profile`;
    client.get(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Get All Devices
  getDevices(cb, errorCb) {
    const url = `${endpoint}/allDevices`;
    client.get(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Forgot Password
  forgotPassword(params, cb, errorCb) {
    const url = `${endpoint}/password/forgot`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  /**
	 * Verify Reset Password Code
	 *
	 * @param {Object} params Params
	 * @param {string} params.email Email
	 * @param {string} params.code Code
	 * @param {*} cb
	 * @param {*} errorCb
	 */
  verifyResetCode(params, cb, errorCb) {
    // const url = `${endpoint}/password/verify`;
    const url = `${endpoint}/password/forgot/verifyUrl/${params.email}/${params.token}`;
    client.get(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  /**
	 * Set Password
	 *
	 * @param {Obbject} payload Payload
	 * @param {string} payload.password New Password
	 * @param {string} confirmationPassword New Password Confirmation
	 * @param {Object} params Params
	 * @param {string} params.email Email
	 * @param {string} params.code Code
	 * @param {*} cb
	 * @param {*} errorCb
	*/
  setPassword(payload, params, cb, errorCb) {
    const url = `${endpoint}/password/set`;
    client.put(url, payload, { params })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Reset Password
  resetPassword(params, cb, errorCb) {
    const url = `${endpoint}/password/reset`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  /**
	 * Verification
	*/
  verification(id, lang, cb, errorCb) {
    const url = `${endpoint}/users/verification/${id}?lang=${lang}`;
    client.put(url, {})
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // update user
  update(user, params, cb, errorCb) {
    const url = `${endpoint}/profile/update`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // changePassword
  changePassword(params, cb, errorCb) {
    const url = `${endpoint}/profile/changePassword`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // send sms code
  sendSmsCode(params, cb, errorCb) {
    const url = `${endpoint}/login/sms/generate`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // verify sms code
  verifySmsCode(params, cb, errorCb) {
    const url = `${endpoint}/login/sms`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // send sms code
  sendLoginToken(params, cb, errorCb) {
    const url = `${endpoint}/login/auto/generate`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // verify sms code
  verifyLoginToken(params, cb, errorCb) {
    const url = `${endpoint}/login/auto`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // verify and auto login
  verifyUser(token, cb, errorCb) {
    const url = `${endpoint}/login/auto/`;
    client.post(url, token)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

};
