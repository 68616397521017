<template>
	<div
		class="update-app" v-if="updateExists__">
		<div class="service-worker">
			<div class="title-service-worker">
				<span class="">{{$t('A new version of Koblatil is available')}}</span>
			</div>
			<div class="subtitle-service-worker">
				<span class="">{{$t('Click Refresh to update')}}</span>
			</div>
      <div class="btn-container ml-6">
        <vs-button @click="refreshApp__()" color="#4690DE" class="vs-button-primary w-full mt-4 p-2">
          {{$t('Refresh')}}
        </vs-button>
      </div>
		</div>
	</div>
</template>
<script>
import updateServiceWorker from '@/mixins/update-service-worker';

export default {
  mixins: [updateServiceWorker],
  components: {},
  data() {
    return {};
  },
  sockets: {
  },
  mounted() {},
  created() {},
  destroyed() {},
  beforeDestroy() {
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
