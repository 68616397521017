import { render, staticRenderFns } from "./VxList.vue?vue&type=template&id=73dad5dc"
import script from "./VxList.vue?vue&type=script&lang=js"
export * from "./VxList.vue?vue&type=script&lang=js"
import style0 from "./VxList.vue?vue&type=style&index=0&id=73dad5dc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports