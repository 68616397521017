import axios from 'axios';

const TOKEN_KEY = 'access_token';
const TOKEN_KEY_ATTENDEE = 'access_token_attendee';

const url = process.env.VUE_APP_API_URL;
let token = localStorage.getItem(TOKEN_KEY);

const currentUrl = window.location.href;
const searchString = 'channels';
if (currentUrl.includes(searchString)) {
  token = localStorage.getItem(TOKEN_KEY_ATTENDEE);
}

const setLastSessionURL = () => {
  const LAST_SESSION_URL = 'LAST_SESSION_URL';
  const lastUrl = window.location.href;
  localStorage.setItem(LAST_SESSION_URL, lastUrl);
};

const globalResponseHandler = (response) => {
  if (typeof (response.data) === 'string') {
    response.data = JSON.parse(response.data);
  }

  return response;
};

const globalErrorHandler = (error) => {
  const { status } = error.response;
  const isTokenExpired = status === 401;
  const isUnauthorized = status === 403;
  const isMaintenance = status === 503;
  if (isTokenExpired) {
    setLastSessionURL();
    localStorage.removeItem(TOKEN_KEY);
    const originalRequest = error.config;
    delete originalRequest.headers.Authorization;
    setTimeout(() => {
      window.location.href = '/login';
    }, 200);
  } else if (isUnauthorized) {
    setLastSessionURL();
    setTimeout(() => {
      window.location.href = '/';
    }, 200);
  } else if (isMaintenance) {
    setLastSessionURL();
    if (window.location.pathname !== '/maintenance') {
      window.location.href = '/maintenance';
    }
  }
  return Promise.reject(error);
};

const client = axios.create({
  baseURL: `${url}/v1`,
  headers: {
    Authorization: `Bearer ${token}`,
    'Accept-Language': 'no',
  },
});

// Add a response interceptor
client.interceptors.response.use(globalResponseHandler, globalErrorHandler);

export default client;
