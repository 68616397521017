// Languages
export const languages = [{
  value: 'en',
  label: 'English',
},
{
  value: 'id',
  label: 'Bahasa Indonesia',
},
];

// File MIME Types
export const fileTypes = {
  document: ['application/pdf', 'application/msword', 'application/vnd.ms-excel'],
  video: ['video/mp4'],
  image: ['image/png', 'image/jpg', 'image/jpeg'],
};

// User Excel Schema
export const USER_SCHEMA = {
  username: {
    prop: 'username',
    type: String,
    required: true,
  },
  email: {
    prop: 'email',
    type: String,
    required: true,
  },
  role: {
    prop: 'role',
    type: String,
    required: true,
  },
  password: {
    prop: 'password',
    type: String,
    required: true,
    parse(value) {
      const passwordString = String(value);
      return passwordString;
    },
  },
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  address: {
    prop: 'address',
    type: String,
    required: false,
  },
  phone_number: {
    prop: 'phone_number',
    type: String,
    required: true,
  },
  region: {
    prop: 'region',
    type: String,
    required: false,
  },
  whatsapp_number: {
    prop: 'whatsapp_number',
    type: String,
    required: false,
  },
  facebook_url: {
    prop: 'facebook_url',
    type: String,
    required: false,
  },
  twitter_url: {
    prop: 'twitter_url',
    type: String,
    required: false,
  },
  linkedin_url: {
    prop: 'linkedin_url',
    type: String,
    required: false,
  },
  instagram_url: {
    prop: 'instagram_url',
    type: String,
    required: false,
  },
  job_title: {
    prop: 'job_title',
    type: String,
    required: false,
  },
  birth_date: {
    prop: 'birth_date',
    type: String,
    required: false,
  },
  gender: {
    prop: 'gender',
    type: String,
    required: false,
  },
  picture: {
    prop: 'picture',
    type: String,
    required: false,
  },
};

// Participant Excel Schema
export const PARTICIPANT_SCHEMA = {
  username: {
    prop: 'username',
    type: String,
    required: true,
  },
  email: {
    prop: 'email',
    type: String,
    required: true,
  },
  password: {
    prop: 'password',
    type: String,
    required: true,
    parse(value) {
      const passwordString = String(value);
      return passwordString;
    },
  },
  role: {
    prop: 'role',
    type: String,
    required: true,
  },
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  address: {
    prop: 'address',
    type: String,
    required: false,
  },
  phone_number: {
    prop: 'phone_number',
    type: String,
    required: true,
  },
  company: {
    prop: 'company',
    type: String,
    required: false,
  },
  job_title: {
    prop: 'job_title',
    type: String,
    required: false,
  },
  birth_date: {
    prop: 'birth_date',
    type: String,
    required: false,
  },
  gender: {
    prop: 'gender',
    type: String,
    required: false,
  },
  // picture: {
  // 	prop: 'picture',
  // 	type: String,
  // 	required: false,
  // },
  // language: {
  // 	prop: 'language',
  // 	type: String,
  // 	required: false,
  // },
  code: {
    prop: 'code',
    type: String,
    required: false,
  },
  // category: {
  // 	prop: 'category',
  // 	type: String,
  // 	required: false,
  // },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
};

// Category Excel Schema
export const CATEGORY_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  event_id: {
    prop: 'event_id',
    type: String,
    required: false,
  },
};

// Prizes Excel Schema
export const PRIZE_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  status: {
    prop: 'status',
    type: String,
    required: false,
  },
  description: {
    prop: 'description',
    type: String,
    required: false,
  },
  quantity: {
    prop: 'quantity',
    type: String,
    required: false,
  },
  is_participate_again: {
    prop: 'is_participate_again',
    type: String,
    required: false,
  },
};

// GUests Excel Schema
export const GUEST_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  company: {
    prop: 'company',
    type: String,
    required: false,
  },
  address: {
    prop: 'address',
    type: String,
    required: false,
  },
  job_title: {
    prop: 'job_title',
    type: String,
    required: false,
  },
  order: {
    prop: 'order',
    type: String,
    required: false,
  },
  status: {
    prop: 'status',
    type: String,
    required: false,
  },
  description: {
    prop: 'description',
    type: String,
    required: false,
  },
  is_vip: {
    prop: 'is_vip',
    type: String,
    required: false,
  },
};

// Code Excel Schema
export const CODE_SCHEMA = {
  code: {
    prop: 'code',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  is_check_in: {
    prop: 'is_check_in',
    type: String,
    required: true,
  },
  is_check_out: {
    prop: 'is_check_out',
    type: String,
    required: true,
  },
};

// Room Excel Schema
export const ROOM_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  capacity: {
    prop: 'capacity',
    type: String,
    required: true,
  },
  code: {
    prop: 'code',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: true,
  },
  is_vip: {
    prop: 'is_vip',
    type: String,
    required: true,
  },
};

// Room Excel Schema
export const ACHIEVEMENT_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  category: {
    prop: 'category',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: true,
  },
};

// Galleries Excel Schema
export const GALLERIES_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  file_path: {
    prop: 'file_path',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: true,
  },
  is_shared: {
    prop: 'is_shared',
    type: String,
    required: true,
  },
  is_published: {
    prop: 'is_published',
    type: String,
    required: true,
  },
};

// Display Excel Schema
export const DISPLAY_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: false,
  },
  is_active: {
    prop: 'is_active',
    type: String,
    required: true,
  },
  is_default: {
    prop: 'is_default',
    type: String,
    required: true,
  },
};

// Schedules Excel Schema
export const SCHEDULE_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: false,
  },
  start_time: {
    prop: 'start_time',
    type: String,
    required: true,
  },
  end_time: {
    prop: 'end_time',
    type: String,
    required: true,
  },
  order: {
    prop: 'order',
    type: String,
    required: true,
  },
};

// assets excel schema
export const ASSET_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: false,
  },
  quantity: {
    prop: 'quantity',
    type: String,
    required: true,
  },
  tags: {
    prop: 'tags',
    type: String,
    required: true,
  },
};

// facilities  excel schema
export const FACILITY_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: false,
  },
  order: {
    prop: 'order',
    type: String,
    required: true,
  },
};

// Accomodations excel schema
export const ACCOMODATION_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  type: {
    prop: 'type',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: false,
  },
  order: {
    prop: 'order',
    type: String,
    required: true,
  },
};

// TEAMS excel schema
export const TEAM_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  job_description: {
    prop: 'job_description',
    type: String,
    required: true,
  },
  order: {
    prop: 'order',
    type: String,
    required: true,
  },
};

// TEAMS Member excel schema
export const TEAM_MEMBER_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  job_description: {
    prop: 'job_description',
    type: String,
    required: true,
  },
  order: {
    prop: 'order',
    type: String,
    required: true,
  },
};

// Feedback excel schema
export const FEEDBACK_SCHEMA = {
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  description: {
    prop: 'description',
    type: String,
    required: true,
  },
  status: {
    prop: 'status',
    type: String,
    required: true,
  },
  categories: {
    prop: 'categories',
    type: String,
    required: true,
  },
  rate_level: {
    prop: 'rate_level',
    type: String,
    required: true,
  },
};

// Crews Excel Schema
export const CREW_SCHEMA = {
  username: {
    prop: 'username',
    type: String,
    required: true,
  },
  email: {
    prop: 'email',
    type: String,
    required: true,
  },
  role: {
    prop: 'role',
    type: String,
    required: true,
  },
  password: {
    prop: 'password',
    type: String,
    required: true,
    parse(value) {
      const passwordString = String(value);
      return passwordString;
    },
  },
  name: {
    prop: 'name',
    type: String,
    required: true,
  },
  phone_number: {
    prop: 'phone_number',
    type: String,
    required: true,
  },
  gender: {
    prop: 'gender',
    type: String,
    required: false,
  },
  picture: {
    prop: 'picture',
    type: String,
    required: false,
  },
};


// Timezones
export const TIMEZONE_OPTIONS = [{
  value: 'Europe/Oslo',
  text: 'Europe/Oslo',
},
{
  value: 'America/Los_Angeles',
  text: 'America/Los Angeles',
},
{
  value: 'Europe/London',
  text: 'Europe/London',
},
{
  value: 'America/New_York',
  text: 'America/New York',
},
{
  value: 'Asia/Tokyo',
  text: 'Asia/Tokyo',
},

];

// Roles Options
export const ROLES_OPTIONS = [{
  key: 'super_admin',
  label: 'Super Admin',
},
{
  key: 'client',
  label: 'Client',
},
];

// Category Type Options
export const CATEGORY_TYPE_OPTIONS = [{
  key: 'event',
  label: 'Event',
}];

export const CURRENCY_SYMBOL_OPTIONS = [{
  key: 'Kr.',
  label: 'Kr.',
},
{
  key: '$',
  label: '$',
},
{
  key: '€',
  label: '€',
},
];

export const CURRENCY_NAME_OPTIONS = [{
  key: 'Krone',
  label: 'Krone',
},
{
  key: 'USD',
  label: 'USD',
},
{
  key: 'Euro',
  label: 'Euro',
},
];

// Project Status
export const PROJECT_STATUSES = [{
  key: 'waiting',
  label: 'Waiting',
},
{
  key: 'approved',
  label: 'Approved',
},
{
  key: 'rejected',
  label: 'Rejected',
},
{
  key: 'processed',
  label: 'Processed',
},
{
  key: 'on_going',
  label: 'On Going',
},
{
  key: 'done',
  label: 'Done',
},
{
  key: 'review',
  label: 'Review',
},
{
  key: 'revision',
  label: 'Revision',
},
{
  key: 'completed',
  label: 'Completed',
},
{
  key: 'canceled',
  label: 'Canceled',
},
];

// Dummy Global Terms & Condition
export const dummyTerms = /* html */ `
	<ol>
		<li>Office working hours, from Monday to Friday, 08:00 to 16:00</li>
		<li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</li>
		<li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</li>
		<li>Voyez ce jeu exquis wallon, de graphie en kit mais bref. Portez ce vieux whisky au juge blond</li>
		<li>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain</li>
	</ol>
`;

export const documentExtensions = ['.doc', '.docx'];
export const imageExtensions = ['.jpg', '.jpeg', '.png'];
export const videoExtensions = ['.mp4', '.webm'];

export const allExtensions = {
  document: documentExtensions,
  image: imageExtensions,
  video: videoExtensions,
};

// Role Types
export const ROLE_LEVELS = {
  super_admin: 'admin',
  client: 'client',
  team: 'team',
  participant: 'participant',
};

// Amenities Types
export const amenitiesTypes = [{
  value: 'fb',
  text: 'F & B (Food and Beverage)',
},
{
  value: 'gift',
  text: 'Gift',
},
{
  value: 'certificate',
  text: 'Certificate',
},
{
  value: 'trophy',
  text: 'Trophy',
},
{
  value: 'goody-bag',
  text: 'Goody Bag',
},
{
  value: 'others',
  text: 'Others',
},
];

export const BROADCAST_TEMPLATES = [{
  id: 'invitation',
  title: 'Invitation',
  content: 'You are invited by [EVENT_OWNER] to attend event on: [EVENT_NAME].\n\nClick this link to see event: [EVENT_URL]\n\nHere is your access code: [ACCESS_CODE]',
},
{
  id: 'login_information',
  title: 'Login Information',
  content: 'This is your login url to access the system: [LOGIN_URL]',
},
{
  id: 'reset_password',
  title: 'Reset Password',
  content: 'This is the url to change your password: [RESET_PASSWORD_URL]',
},
{
  id: 'reminder',
  title: 'Reminder',
  content: 'Reminder. You are invited by [EVENT_OWNER] to attend event on: [EVENT_NAME].\n\nClick this link to see event: [EVENT_URL] \n\nHere is your access code: [ACCESS_CODE]',
},
];

export const TIMEZONE_JSON = [
  {
    BD: [{
      tzid: 'Asia/Dhaka',
      name: 'Bangladesh',
    }],
    BE: [{
      tzid: 'Europe/Brussels',
      name: 'Romance',
    }],
    BF: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    BG: [{
      tzid: 'Europe/Sofia',
      name: 'FLE',
    }],
    BA: [{
      tzid: 'Europe/Belgrade',
      name: 'Central Europe',
    }],
    BB: [{
      tzid: 'America/Barbados',
      name: 'SA Western',
    }],
    WF: [{
      tzid: 'Pacific/Wallis',
      name: 'UTC+12',
    }],
    BL: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    BM: [{
      tzid: 'Atlantic/Bermuda',
      name: 'Atlantic',
    }],
    BN: [{
      tzid: 'Asia/Brunei',
      name: 'Singapore',
    }],
    BO: [{
      tzid: 'America/La_Paz',
      name: 'SA Western',
    }],
    JP: [{
      tzid: 'Asia/Tokyo',
      name: 'Tokyo',
    }],
    BI: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
    BJ: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    BT: [{
      tzid: 'Asia/Thimphu',
      name: 'Bangladesh',
    }],
    JM: [{
      tzid: 'America/Jamaica',
      name: 'SA Pacific',
    }],
    JO: [{
      tzid: 'Asia/Amman',
      name: 'Jordan',
    }],
    WS: [{
      tzid: 'Pacific/Apia',
      name: 'Samoa',
    }],
    BQ: [{
      tzid: 'America/Curacao',
      name: 'SA Western',
    }],
    BR: [{
      tzid: 'America/Noronha',
      name: 'UTC-02',
    }, {
      tzid: 'America/Belem',
      name: 'Belem',
    }, {
      tzid: 'America/Fortaleza',
      name: 'Fortaleza',
    }, {
      tzid: 'America/Recife',
      name: 'Recife',
    }, {
      tzid: 'America/Araguaina',
      name: 'Araguaina',
    }, {
      tzid: 'America/Maceio',
      name: 'Maceio',
    }, {
      tzid: 'America/Bahia',
      name: 'Bahia',
    }, {
      tzid: 'America/Sao_Paulo',
      name: 'Sao Paulo',
    }, {
      tzid: 'America/Campo_Grande',
      name: 'Campo Grande',
    }, {
      tzid: 'America/Cuiaba',
      name: 'Cuiaba',
    }, {
      tzid: 'America/Porto_Velho',
      name: 'Velho',
    }, {
      tzid: 'America/Boa_Vista',
      name: 'Boa Vista',
    }, {
      tzid: 'America/Manaus',
      name: 'Manaus',
    }, {
      tzid: 'America/Rio_Branco',
      name: 'Rio Branco',
    }],
    BS: [{
      tzid: 'America/Nassau',
      name: 'Eastern',
    }],
    JE: [{
      tzid: 'Europe/London',
      name: 'GMT',
    }],
    BY: [{
      tzid: 'Europe/Minsk',
      name: 'Belarus',
    }],
    BZ: [{
      tzid: 'America/Belize',
      name: 'Central America',
    }],
    RU: [{
      tzid: 'Europe/Kaliningrad',
      name: 'Kaliningrad',
    }, {
      tzid: 'Europe/Moscow',
      name: 'Russian',
    }, {
      tzid: 'Europe/Simferopol',
      name: 'Russian',
    }, {
      tzid: 'Europe/Volgograd',
      name: 'Russian',
    }, {
      tzid: 'Europe/Samara',
      name: 'Russia Zone 3',
    }, {
      tzid: 'Asia/Yekaterinburg',
      name: 'Ekaterinburg',
    }, {
      tzid: 'Asia/Omsk',
      name: 'N. Central Asia',
    }, {
      tzid: 'Asia/Novosibirsk',
      name: 'N. Central Asia',
    }, {
      tzid: 'Asia/Novokuznetsk',
      name: 'North Asia',
    }, {
      tzid: 'Asia/Krasnoyarsk',
      name: 'North Asia',
    }, {
      tzid: 'Asia/Irkutsk',
      name: 'North Asia East',
    }, {
      tzid: 'Asia/Chita',
      name: 'Yakutsk',
    }, {
      tzid: 'Asia/Yakutsk',
      name: 'Yakutsk',
    }, {
      tzid: 'Asia/Khandyga',
      name: 'Yakutsk',
    }, {
      tzid: 'Asia/Vladivostok',
      name: 'Vladivostok',
    }, {
      tzid: 'Asia/Ust-Nera',
      name: 'Vladivostok',
    }, {
      tzid: 'Asia/Magadan',
      name: 'Magadan',
    }, {
      tzid: 'Asia/Sakhalin',
      name: 'Vladivostok',
    }, {
      tzid: 'Asia/Srednekolymsk',
      name: 'Russia Zone 10',
    }, {
      tzid: 'Asia/Kamchatka',
      name: 'Russia Zone 11',
    }, {
      tzid: 'Asia/Anadyr',
      name: 'Russia Zone 11',
    }],
    RW: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
    RS: [{
      tzid: 'Europe/Belgrade',
      name: 'Central Europe',
    }],
    LT: [{
      tzid: 'Europe/Vilnius',
      name: 'FLE',
    }],
    RE: [{
      tzid: 'Indian/Reunion',
      name: 'Mauritius',
    }],
    LU: [{
      tzid: 'Europe/Luxembourg',
      name: 'W. Europe',
    }],
    LR: [{
      tzid: 'Africa/Monrovia',
      name: 'Greenwich',
    }],
    RO: [{
      tzid: 'Europe/Bucharest',
      name: 'GTB',
    }],
    TK: [{
      tzid: 'Pacific/Fakaofo',
      name: 'Tonga',
    }],
    GW: [{
      tzid: 'Africa/Bissau',
      name: 'Greenwich',
    }],
    GU: [{
      tzid: 'Pacific/Guam',
      name: 'West Pacific',
    }],
    GT: [{
      tzid: 'America/Guatemala',
      name: 'Central America',
    }],
    GR: [{
      tzid: 'Europe/Athens',
      name: 'GTB',
    }],
    GQ: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    GP: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    BH: [{
      tzid: 'Asia/Qatar',
      name: 'Arab',
    }],
    GY: [{
      tzid: 'America/Guyana',
      name: 'SA Western',
    }],
    GG: [{
      tzid: 'Europe/London',
      name: 'GMT',
    }],
    GF: [{
      tzid: 'America/Cayenne',
      name: 'SA Eastern',
    }],
    GE: [{
      tzid: 'Asia/Tbilisi',
      name: 'Georgian',
    }],
    GD: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    GB: [{
      tzid: 'Europe/London',
      name: 'GMT',
    }],
    GA: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    GN: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    GM: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    GL: [{
      tzid: 'America/Godthab',
      name: 'Greenland',
    }, {
      tzid: 'America/Danmarkshavn',
      name: 'UTC',
    }, {
      tzid: 'America/Scoresbysund',
      name: 'Azores',
    }, {
      tzid: 'America/Thule',
      name: 'Atlantic',
    }],
    GI: [{
      tzid: 'Europe/Gibraltar',
      name: 'W. Europe',
    }],
    GH: [{
      tzid: 'Africa/Accra',
      name: 'Greenwich',
    }],
    OM: [{
      tzid: 'Asia/Dubai',
      name: 'Arabian',
    }],
    BW: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
    HR: [{
      tzid: 'Europe/Belgrade',
      name: 'Central Europe',
    }],
    HT: [{
      tzid: 'America/Port-au-Prince',
      name: 'Eastern',
    }],
    HU: [{
      tzid: 'Europe/Budapest',
      name: 'Central Europe',
    }],
    HK: [{
      tzid: 'Asia/Hong_Kong',
      name: 'China',
    }],
    HN: [{
      tzid: 'America/Tegucigalpa',
      name: 'Central America',
    }],
    LV: [{
      tzid: 'Europe/Riga',
      name: 'FLE',
    }],
    AD: [{
      tzid: 'Europe/Andorra',
      name: 'W. Europe',
    }],
    PR: [{
      tzid: 'America/Puerto_Rico',
      name: 'SA Western',
    }],
    PS: [{
      tzid: 'Asia/Gaza',
      name: 'Palestine Time',
    }],
    PW: [{
      tzid: 'Pacific/Palau',
      name: 'Tokyo',
    }],
    PT: [{
      tzid: 'Europe/Lisbon',
      name: 'Lisbon',
    }, {
      tzid: 'Atlantic/Azores',
      name: 'Azores',
    }],
    KR: [{
      tzid: 'Asia/Seoul',
      name: 'Korea',
    }],
    PY: [{
      tzid: 'America/Asuncion',
      name: 'Paraguay',
    }],
    IQ: [{
      tzid: 'Asia/Baghdad',
      name: 'Arabic',
    }],
    PA: [{
      tzid: 'America/Panama',
      name: 'SA Pacific',
    }],
    PF: [{
      tzid: 'Pacific/Tahiti',
      name: 'Hawaiian',
    }],
    PG: [{
      tzid: 'Pacific/Port_Moresby',
      name: 'West Pacific',
    }, {
      tzid: 'Pacific/Bougainville',
      name: 'Central Pacific',
    }],
    PE: [{
      tzid: 'America/Lima',
      name: 'SA Pacific',
    }],
    PK: [{
      tzid: 'Asia/Karachi',
      name: 'Pakistan',
    }],
    PH: [{
      tzid: 'Asia/Manila',
      name: 'Singapore',
    }],
    PN: [{
      tzid: 'Pacific/Pitcairn',
      name: 'Pitcairn Time',
    }],
    PL: [{
      tzid: 'Europe/Warsaw',
      name: 'Central European',
    }],
    PM: [{
      tzid: 'America/Miquelon',
      name: 'Miquelon Time',
    }],
    ZM: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
    EH: [{
      tzid: 'Africa/El_Aaiun',
      name: 'Morocco',
    }],
    EE: [{
      tzid: 'Europe/Tallinn',
      name: 'FLE',
    }],
    EG: [{
      tzid: 'Africa/Cairo',
      name: 'Egypt',
    }],
    ZA: [{
      tzid: 'Africa/Johannesburg',
      name: 'South Africa',
    }],
    EC: [{
      tzid: 'America/Guayaquil',
      name: 'SA Pacific',
    }, {
      tzid: 'Pacific/Galapagos',
      name: 'Central America',
    }],
    AL: [{
      tzid: 'Europe/Tirane',
      name: 'Central Europe',
    }],
    AO: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    KZ: [{
      tzid: 'Asia/Almaty',
      name: 'Almaty',
    }, {
      tzid: 'Asia/Aqtobe',
      name: 'Aqtobe',
    }, {
      tzid: 'Asia/Aqtau',
      name: 'Aqtau',
    }],
    ET: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    SO: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    ZW: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
    KY: [{
      tzid: 'America/Panama',
      name: 'SA Pacific',
    }],
    ES: [{
      tzid: 'Europe/Madrid',
      name: 'Madrid',
    }, {
      tzid: 'Africa/Ceuta',
      name: 'Ceuta',
    }, {
      tzid: 'Atlantic/Canary',
      name: 'GMT',
    }],
    ER: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    ME: [{
      tzid: 'Europe/Belgrade',
      name: 'Central Europe',
    }],
    MD: [{
      tzid: 'Europe/Chisinau',
      name: 'E. Europe',
    }],
    MG: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    MF: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    MA: [{
      tzid: 'Africa/Casablanca',
      name: 'Morocco',
    }],
    MC: [{
      tzid: 'Europe/Monaco',
      name: 'W. Europe',
    }],
    UZ: [{
      tzid: 'Asia/Tashkent',
      name: 'Tashkent',
    }],
    MM: [{
      tzid: 'Asia/Rangoon',
      name: 'Myanmar',
    }],
    ML: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    MO: [{
      tzid: 'Asia/Macau',
      name: 'China',
    }],
    MN: [{
      tzid: 'Asia/Ulaanbaatar',
      name: 'Ulaanbaatar',
    }, {
      tzid: 'Asia/Choibalsan',
      name: 'Choibalsan',
    }],
    MH: [{
      tzid: 'Pacific/Majuro',
      name: 'Majuro',
    }, {
      tzid: 'Pacific/Kwajalein',
      name: 'Kwajalein',
    }],
    MK: [{
      tzid: 'Europe/Belgrade',
      name: 'Central Europe',
    }],
    UM: [{
      tzid: 'Pacific/Pago_Pago',
      name: 'UTC-11',
    }, {
      tzid: 'Pacific/Wake',
      name: 'UTC+12',
    }, {
      tzid: 'Pacific/Honolulu',
      name: 'Hawaiian',
    }],
    MT: [{
      tzid: 'Europe/Malta',
      name: 'W. Europe',
    }],
    MW: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
    MV: [{
      tzid: 'Indian/Maldives',
      name: 'West Asia',
    }],
    MQ: [{
      tzid: 'America/Martinique',
      name: 'SA Western',
    }],
    MP: [{
      tzid: 'Pacific/Guam',
      name: 'West Pacific',
    }],
    MS: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    MR: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    AU: [{
      tzid: 'Antarctica/Macquarie',
      name: 'Central Pacific',
    }, {
      tzid: 'Australia/Hobart',
      name: 'Eastern - Hobart',
    }, {
      tzid: 'Australia/Sydney',
      name: 'Eastern - Sydney',
    }, {
      tzid: 'Australia/Brisbane',
      name: 'Eastern - Brisbane',
    }, {
      tzid: 'Australia/Adelaide',
      name: 'Central- Adelaide',
    }, {
      tzid: 'Australia/Darwin',
      name: 'Central - Darwin',
    }, {
      tzid: 'Australia/Perth',
      name: 'Western',
    }],
    UG: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    MY: [{
      tzid: 'Asia/Kuala_Lumpur',
      name: 'Kuala Lumpur',
    }],
    MX: [{
      tzid: 'America/Mexico_City',
      name: 'Central - Mexico City',
    }, {
      tzid: 'America/Cancun',
      name: 'Eastern',
    }, {
      tzid: 'America/Chihuahua',
      name: 'Mountain - Chihuahua',
    }, {
      tzid: 'America/Hermosillo',
      name: 'Mountain - Hermosillo',
    }, {
      tzid: 'America/Tijuana',
      name: 'Pacific',
    }],
    VU: [{
      tzid: 'Pacific/Efate',
      name: 'Central Pacific',
    }],
    FR: [{
      tzid: 'Europe/Paris',
      name: 'Romance',
    }],
    AW: [{
      tzid: 'America/Curacao',
      name: 'SA Western',
    }],
    SH: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    AF: [{
      tzid: 'Asia/Kabul',
      name: 'Afghanistan',
    }],
    AX: [{
      tzid: 'Europe/Helsinki',
      name: 'FLE',
    }],
    FI: [{
      tzid: 'Europe/Helsinki',
      name: 'FLE',
    }],
    FJ: [{
      tzid: 'Pacific/Fiji',
      name: 'Fiji',
    }],
    FK: [{
      tzid: 'Atlantic/Stanley',
      name: 'SA Eastern',
    }],
    FM: [{
      tzid: 'Pacific/Kosrae',
      name: 'Central Pacific',
    }],
    FO: [{
      tzid: 'Atlantic/Faroe',
      name: 'Faroe Time',
    }],
    NI: [{
      tzid: 'America/Managua',
      name: 'Central America',
    }],
    NL: [{
      tzid: 'Europe/Amsterdam',
      name: 'W. Europe',
    }],
    NO: [{
      tzid: 'Europe/Oslo',
      name: 'W. Europe',
    }],
    NA: [{
      tzid: 'Africa/Windhoek',
      name: 'Namibia',
    }],
    NC: [{
      tzid: 'Pacific/Noumea',
      name: 'Central Pacific',
    }],
    NE: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    NF: [{
      tzid: 'Pacific/Norfolk',
      name: 'Central Pacific',
    }],
    NG: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    NZ: [{
      tzid: 'Pacific/Auckland',
      name: 'New Zealand',
    }],
    NP: [{
      tzid: 'Asia/Kathmandu',
      name: 'Nepal Time',
    }],
    NR: [{
      tzid: 'Pacific/Nauru',
      name: 'UTC+12',
    }],
    NU: [{
      tzid: 'Pacific/Niue',
      name: 'UTC-11',
    }],
    CK: [{
      tzid: 'Pacific/Rarotonga',
      name: 'Hawaiian',
    }],
    CI: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    CH: [{
      tzid: 'Europe/Zurich',
      name: 'W. Europe',
    }],
    CO: [{
      tzid: 'America/Bogota',
      name: 'SA Pacific',
    }],
    CN: [{
      tzid: 'Asia/Shanghai',
      name: 'China',
    }, {
      tzid: 'Asia/Urumqi',
      name: 'Central Asia',
    }],
    CM: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    CL: [{
      tzid: 'America/Santiago',
      name: 'Santiago',
    }, {
      tzid: 'Pacific/Easter',
      name: 'Easter Island',
    }],
    CC: [{
      tzid: 'Indian/Cocos',
      name: 'Myanmar',
    }],
    CA: [{
      tzid: 'America/St_Johns',
      name: 'Newfoundland',
    }, {
      tzid: 'America/Halifax',
      name: 'Atlantic - Halifax',
    }, {
      tzid: 'America/Toronto',
      name: 'Eastern - Toronto',
    }, {
      tzid: 'America/Iqaluit',
      name: 'Eastern - Iqaluit',
    }, {
      tzid: 'America/Winnipeg',
      name: 'Central - Winnipeg',
    }, {
      tzid: 'America/Regina',
      name: 'Central - Regina',
    }, {
      tzid: 'America/Edmonton',
      name: 'Mountain - Edmonton',
    }, {
      tzid: 'America/Yellowknife',
      name: 'Mountain - Yellowknife',
    }, {
      tzid: 'America/Dawson_Creek',
      name: 'Dawson Creek',
    }, {
      tzid: 'America/Vancouver',
      name: 'Pacific - Vancouver',
    }, {
      tzid: 'America/Whitehorse',
      name: 'Pacific - Whitehorse',
    }],
    CG: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    CF: [{
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    CD: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }, {
      tzid: 'Africa/Lagos',
      name: 'W. Central Africa',
    }],
    CZ: [{
      tzid: 'Europe/Prague',
      name: 'Central Europe',
    }],
    CY: [{
      tzid: 'Asia/Nicosia',
      name: 'GTB',
    }],
    CX: [{
      tzid: 'Indian/Christmas',
      name: 'SE Asia',
    }],
    CR: [{
      tzid: 'America/Costa_Rica',
      name: 'Central America',
    }],
    CW: [{
      tzid: 'America/Curacao',
      name: 'SA Western',
    }],
    CV: [{
      tzid: 'Atlantic/Cape_Verde',
      name: 'Cape Verde',
    }],
    CU: [{
      tzid: 'America/Havana',
      name: 'Eastern',
    }],
    SZ: [{
      tzid: 'Africa/Johannesburg',
      name: 'South Africa',
    }],
    SY: [{
      tzid: 'Asia/Damascus',
      name: 'Syria',
    }],
    SX: [{
      tzid: 'America/Curacao',
      name: 'SA Western',
    }],
    KG: [{
      tzid: 'Asia/Bishkek',
      name: 'Central Asia',
    }],
    KE: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    SS: [{
      tzid: 'Africa/Khartoum',
      name: 'E. Africa',
    }],
    SR: [{
      tzid: 'America/Paramaribo',
      name: 'SA Eastern',
    }],
    KI: [{
      tzid: 'Pacific/Tarawa',
      name: 'UTC+12',
    }, {
      tzid: 'Pacific/Enderbury',
      name: 'Tonga',
    }, {
      tzid: 'Pacific/Kiritimati',
      name: 'Line Islands',
    }],
    KH: [{
      tzid: 'Asia/Bangkok',
      name: 'SE Asia',
    }],
    SV: [{
      tzid: 'America/El_Salvador',
      name: 'Central America',
    }],
    KM: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    ST: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    SK: [{
      tzid: 'Europe/Prague',
      name: 'Central Europe',
    }],
    SJ: [{
      tzid: 'Europe/Oslo',
      name: 'W. Europe',
    }],
    SI: [{
      tzid: 'Europe/Belgrade',
      name: 'Central Europe',
    }],
    KP: [{
      tzid: 'Asia/Pyongyang',
      name: 'North Korea',
    }],
    KW: [{
      tzid: 'Asia/Riyadh',
      name: 'Arab',
    }],
    SN: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    SM: [{
      tzid: 'Europe/Rome',
      name: 'W. Europe',
    }],
    SL: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    SC: [{
      tzid: 'Indian/Mahe',
      name: 'Mauritius',
    }],
    SB: [{
      tzid: 'Pacific/Guadalcanal',
      name: 'Central Pacific',
    }],
    SA: [{
      tzid: 'Asia/Riyadh',
      name: 'Arab',
    }],
    SG: [{
      tzid: 'Asia/Singapore',
      name: 'Singapore',
    }],
    SE: [{
      tzid: 'Europe/Stockholm',
      name: 'W. Europe',
    }],
    SD: [{
      tzid: 'Africa/Khartoum',
      name: 'E. Africa',
    }],
    DO: [{
      tzid: 'America/Santo_Domingo',
      name: 'SA Western',
    }],
    DM: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    DJ: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    DK: [{
      tzid: 'Europe/Copenhagen',
      name: 'Romance',
    }],
    DE: [{
      tzid: 'Europe/Berlin',
      name: 'Berlin',
    }],
    YE: [{
      tzid: 'Asia/Riyadh',
      name: 'Arab',
    }],
    AT: [{
      tzid: 'Europe/Vienna',
      name: 'W. Europe',
    }],
    DZ: [{
      tzid: 'Africa/Algiers',
      name: 'W. Central Africa',
    }],
    US: [{
      tzid: 'America/New_York',
      name: 'Eastern',
    }, {
      tzid: 'America/Chicago',
      name: 'Central',
    }, {
      tzid: 'America/Denver',
      name: 'Mountain',
    }, {
      tzid: 'America/Phoenix',
      name: 'Mountain - AZ',
    }, {
      tzid: 'America/Los_Angeles',
      name: 'Pacific',
    }, {
      tzid: 'America/Anchorage',
      name: 'Alaskan',
    }, {
      tzid: 'Pacific/Honolulu',
      name: 'Hawaiian',
    }],
    UY: [{
      tzid: 'America/Montevideo',
      name: 'Montevideo',
    }],
    YT: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    MU: [{
      tzid: 'Indian/Mauritius',
      name: 'Mauritius',
    }],
    KN: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    LB: [{
      tzid: 'Asia/Beirut',
      name: 'Middle East',
    }],
    LC: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    LA: [{
      tzid: 'Asia/Bangkok',
      name: 'SE Asia',
    }],
    TV: [{
      tzid: 'Pacific/Funafuti',
      name: 'UTC+12',
    }],
    TW: [{
      tzid: 'Asia/Taipei',
      name: 'Taipei',
    }],
    TT: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    TR: [{
      tzid: 'Europe/Istanbul',
      name: 'Turkey',
    }],
    LK: [{
      tzid: 'Asia/Colombo',
      name: 'Sri Lanka',
    }],
    LI: [{
      tzid: 'Europe/Zurich',
      name: 'W. Europe',
    }],
    TN: [{
      tzid: 'Africa/Tunis',
      name: 'W. Central Africa',
    }],
    TO: [{
      tzid: 'Pacific/Tongatapu',
      name: 'Tonga',
    }],
    TL: [{
      tzid: 'Asia/Dili',
      name: 'Tokyo',
    }],
    TM: [{
      tzid: 'Asia/Ashgabat',
      name: 'West Asia',
    }],
    TJ: [{
      tzid: 'Asia/Dushanbe',
      name: 'West Asia',
    }],
    LS: [{
      tzid: 'Africa/Johannesburg',
      name: 'South Africa',
    }],
    TH: [{
      tzid: 'Asia/Bangkok',
      name: 'SE Asia',
    }],
    TG: [{
      tzid: 'Africa/Abidjan',
      name: 'Greenwich',
    }],
    TD: [{
      tzid: 'Africa/Ndjamena',
      name: 'W. Central Africa',
    }],
    TC: [{
      tzid: 'America/Grand_Turk',
      name: 'SA Western',
    }],
    LY: [{
      tzid: 'Africa/Tripoli',
      name: 'Libya',
    }],
    VA: [{
      tzid: 'Europe/Rome',
      name: 'W. Europe',
    }],
    VC: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    AE: [{
      tzid: 'Asia/Dubai',
      name: 'Arabian',
    }],
    VE: [{
      tzid: 'America/Caracas',
      name: 'Venezuela',
    }],
    AG: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    VG: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    AI: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    VI: [{
      tzid: 'America/Port_of_Spain',
      name: 'SA Western',
    }],
    IS: [{
      tzid: 'Atlantic/Reykjavik',
      name: 'Greenwich',
    }],
    IR: [{
      tzid: 'Asia/Tehran',
      name: 'Iran',
    }],
    AM: [{
      tzid: 'Asia/Yerevan',
      name: 'Caucasus',
    }],
    IT: [{
      tzid: 'Europe/Rome',
      name: 'W. Europe',
    }],
    VN: [{
      tzid: 'Asia/Bangkok',
      name: 'SE Asia',
    }],
    AS: [{
      tzid: 'Pacific/Pago_Pago',
      name: 'UTC-11',
    }],
    AR: [{
      tzid: 'America/Argentina/Salta',
      name: 'Argentina',
    }],
    IM: [{
      tzid: 'Europe/London',
      name: 'GMT',
    }],
    IL: [{
      tzid: 'Asia/Jerusalem',
      name: 'Israel',
    }],
    IO: [{
      tzid: 'Indian/Chagos',
      name: 'Central Asia',
    }],
    IN: [{
      tzid: 'Asia/Kolkata',
      name: 'India',
    }],
    TZ: [{
      tzid: 'Africa/Nairobi',
      name: 'E. Africa',
    }],
    AZ: [{
      tzid: 'Asia/Baku',
      name: 'Azerbaijan',
    }],
    IE: [{
      tzid: 'Europe/Dublin',
      name: 'GMT',
    }],
    ID: [{
      tzid: 'Asia/Jakarta',
      name: 'Jakarta',
    }, {
      tzid: 'Asia/Makassar',
      name: 'Singapore',
    }, {
      tzid: 'Asia/Jayapura',
      name: 'Tokyo',
    }],
    UA: [{
      tzid: 'Europe/Kiev',
      name: 'Kiev',
    }],
    QA: [{
      tzid: 'Asia/Qatar',
      name: 'Arab',
    }],
    MZ: [{
      tzid: 'Africa/Maputo',
      name: 'South Africa',
    }],
  },
];

export const COUNTRY_CODE = [
  {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia & Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    GB: 'Britain (UK)',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    BQ: 'Caribbean NL',
    KY: 'Cayman Islands',
    CF: 'Central African Rep.',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CD: 'Congo (Dem. Rep.)',
    CG: 'Congo (Rep.)',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curacao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    CI: "Côte d'Ivoire",
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    TL: 'East Timor',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'Korea (North)',
    KR: 'Korea (South)',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar (Burma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    RE: 'Réunion',
    AS: 'Samoa (American)',
    WS: 'Samoa (western)',
    SM: 'San Marino',
    ST: 'Sao Tome & Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St Barthelemy',
    SH: 'St Helena',
    KN: 'St Kitts & Nevis',
    LC: 'St Lucia',
    SX: 'St Maarten (Dutch)',
    MF: 'St Martin (French)',
    PM: 'St Pierre & Miquelon',
    VC: 'St Vincent',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard & Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks & Caicos Is',
    TV: 'Tuvalu',
    UM: 'US minor outlying islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Virgin Islands (UK)',
    VI: 'Virgin Islands (US)',
    WF: 'Wallis & Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland Islands',
  },
];
