<template>
	<modal size="medium" :hide-footer="true" @close="close" v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Connection Lost') }}</h5>
		</div>
		<div slot="body">
			<div class="row connection-container">
				<div class="col-md-12">
					<div class="connection-wrapper">
						<div class="icon-wrapper">
							<i class="material-icons">warning</i>
							<div class="icon-info-wrapper">{{ $t('No Connection') }}</div>
						</div>
						<div>{{ $t('You need an internet connection to use this system') }}</div>
						<div>{{ $t('As soon as you have connected to the internet again, you can continue working') }}</div>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'ModalLostConnection',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    agenda: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit('close');
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.connection-container {
	padding: 1em 1em 3em;
}

.connection-wrapper {
	text-align: center;
}

.icon-wrapper {
	padding: 1em;

	i {
		font-size: 90px;
		color: #EE1D23;
	}

	.icon-info-wrapper {
		font-weight: bold;
		font-size: 16px;
	}
}
</style>
