import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/main/Main.vue'),
    children: [
      // Home
      {
        path: '/',
        name: 'events',
        component: () => import('@/views/Events.vue'),
        meta: {
          pageTitle: 'All Events',
          rule: 'admin',
        },
      },
      // Users
      {
        path: '/users',
        name: 'users',
        component: () => import('@/views/Users.vue'),
        meta: {
          pageTitle: 'Manage Users',
          rule: 'admin',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Users', active: true },
          ],
        },
      },
      // Currencies
      {
        path: '/currencies',
        name: 'currencies',
        component: () => import('@/views/Currencies.vue'),
        meta: {
          pageTitle: 'Manage Currencies',
          rule: 'admin',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Currencies', active: true },
          ],
        },
      },
      // Settings
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/UserSettings.vue'),
        meta: {
          pageTitle: 'Manage Settings',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Settings', active: true },
          ],
        },
      },
      // Profile Page
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/pages/Profile.vue'),
        meta: {
          pageTitle: 'Profile',
          rule: 'editor',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Profile', active: true },
          ],
          props: route => ({
            slug: route.params.slug,
          }),
        },
      },
      // Events
      {
        path: '/events',
        name: 'allEvents',
        component: () => import('@/views/Events.vue'),
        meta: {
          pageTitle: 'All Events',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', active: true },
          ],
        },
      },
      // Event Details
      {
        path: '/events/:slug/details',
        name: 'EventDetails',
        component: () => import('@/views/EventDetails.vue'),
        meta: {
          pageTitle: 'Event',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Overview', active: true },
          ],
          props: route => ({
            slug: route.params.slug,
          }),
        },
      },
      // User Event Details
      {
        path: '/event/:slug/user/details',
        name: 'UserEventDetails',
        component: () => import('@/views/UserEventDetails.vue'),
        meta: {
          pageTitle: 'Event',
          rule: 'editor',
          props: route => ({
            slug: route.params.slug,
          }),
        },
      },
      // My Event Details
      {
        path: '/my-events',
        name: 'myEvents',
        component: () => import('@/views/MyEvents.vue'),
        meta: {
          pageTitle: 'Event',
          rule: 'editor',
        },
      },
      // User invoice Event
      {
        path: '/transactions/:id',
        name: 'UserEventInvoice',
        component: () => import('@/views/UserEventInvoice.vue'),
        meta: {
          pageTitle: 'Event',
          rule: 'editor',
          props: route => ({
            id: route.params.id,
          }),
        },
      },
      // Attendees
      {
        path: '/events/:eventSlug/attendees',
        name: 'EventAttendees',
        component: () => import('@/views/Participants.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
          mode: 'invited',
        }),
        meta: {
          pageTitle: 'Attendees',
          rule: 'admin || client || team',
          breadcrumb: (route) => {
            const links = [
              { title: 'Home', url: '/' },
              { title: 'Events', url: '/events' },
              { title: 'Event', url: `/events/${route.params.eventSlug}` },
              { title: 'Attendees', active: true },
            ];
            return links;
          },
        },
      },
      // Client
      {
        path: '/manage/clients',
        name: 'Client',
        component: () => import('@/views/Clients.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Polls', active: true },
          ],
          rule: 'admin',
        },
      },
      // Team
      {
        path: '/manage/teams',
        name: 'Teams',
        component: () => import('@/views/Teams.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Teams',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Teams', url: '/teams' },
            { title: 'Teams', active: true },
          ],
          rule: 'admin || client',
        },
      },
      // Client
      {
        path: '/manage/announcement',
        name: 'Announcement',
        component: () => import('@/views/Announcement.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Announcement',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Polls', active: true },
          ],
          rule: 'admin',
        },
      },
      // Event Metrics
      {
        path: '/events/:eventSlug/metrics',
        name: 'EventMetrics',
        component: () => import('@/views/EventMetrics.vue'),
        meta: {
          pageTitle: 'Event Metrics',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Metrics', active: true },
          ],
          props: route => ({
            slug: route.params.slug,
          }),
        },
      },
      // Event Homepage
      {
        path: '/events/:slug/homepage',
        name: 'EventHomepage',
        component: () => import('@/views/EventHomepage.vue'),
        meta: {
          pageTitle: 'Homepage',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Homepage', active: true },
          ],
          props: route => ({
            slug: route.params.slug,
          }),
        },
      },
      // Event Interactive
      {
        path: '/events/:eventSlug/qna',
        name: 'EventQnA',
        component: () => import('@/views/EventQnA.vue'),
        meta: {
          pageTitle: 'EventQnA',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Q&A', active: true },
          ],
          props: route => ({
            eventSlug: route.params.eventSlug,
          }),
        },
      },
      // Event Interactive
      {
        path: '/events/:eventSlug/polls',
        name: 'EventPolls',
        component: () => import('@/views/EventPolls.vue'),
        meta: {
          pageTitle: 'EventPolls',
          rule: 'admin || client || team',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Events', url: '/events' },
            { title: 'Polls', active: true },
          ],
          props: route => ({
            eventSlug: route.params.eventSlug,
          }),

        },
      },
      // Event Question
      {
        path: '/events/:eventSlug/interactive/questions',
        name: 'eventQuestions',
        component: () => import('@/views/EventQuestions.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Question',
          rule: 'admin || client || team',
          breadcrumb: (route) => {
            const links = [
              { title: 'Home', url: '/' },
              { title: 'Events', url: '/events' },
              { title: 'Interactive', url: `/events/${route.params.eventSlug}/interactive` },
              { title: 'Question', active: true },
            ];
            return links;
          },
        },
      },
      // Event Question moderated
      {
        path: '/events/:eventSlug/interactive/questions/moderated',
        name: 'eventQuestionsModerated',
        component: () => import('@/views/EventQuestionsModerated.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Moderated Question',
          rule: 'editor',
          breadcrumb: (route) => {
            const links = [
              { title: 'Home', url: '/' },
              { title: 'Events', url: '/events' },
              { title: 'Interactive', url: `/events/${route.params.eventSlug}/interactive` },
              { title: 'Moderated Question', active: true },
            ];
            return links;
          },
        },
      },
      // Event Chat
      {
        path: '/events/:eventSlug/interactive/chats',
        name: 'eventChats',
        component: () => import('@/views/EventChats.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Chat',
          rule: 'editor',
          breadcrumb: (route) => {
            const links = [
              { title: 'Home', url: '/' },
              { title: 'Events', url: '/events' },
              { title: 'Interactive', url: `/events/${route.params.eventSlug}/interactive` },
              { title: 'Chat', active: true },
            ];
            return links;
          },
        },
      },
      // Event Survey
      {
        path: '/events/:eventSlug/interactive/surveys',
        name: 'eventSurveys',
        component: () => import('@/views/EventSurveys.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Survey',
          rule: 'editor',
          breadcrumb: (route) => {
            const links = [
              { title: 'Home', url: '/' },
              { title: 'Events', url: '/events' },
              { title: 'Interactive', url: `/events/${route.params.eventSlug}/interactive` },
              { title: 'Survey', active: true },
            ];
            return links;
          },
        },
      },
      {
        path: '/manage/calendar',
        name: 'EventCalendar',
        component: () => import('@/views/Calendar.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Event Calendar',
          rule: 'admin || client || team',
        },
      },
    ],
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/full-page/FullPage.vue'),
    children: [
      // Auth Callback
      {
        path: '/callback',
        name: 'auth-callback',
        component: () => import('@/views/Callback.vue'),
        meta: {
          rule: 'editor',
        },
      },
      // Login
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/login/Login.vue'),
        meta: {
          pageTitle: 'Login',
          rule: 'editor',
        },
      },
      // auto Login
      {
        path: '/auto_login',
        name: 'autoLogin',
        component: () => import('@/views/pages/login/AutoLogin.vue'),
        meta: {
          pageTitle: 'Auto Login',
          rule: 'editor',
        },
      },
      // Login SMS
      {
        path: '/login-sms',
        name: 'loginSMS',
        component: () => import('@/views/pages/login/LoginSMS.vue'),
        meta: {
          pageTitle: 'Login SMS',
          rule: 'editor',
        },
      },
      // Logout
      {
        path: '/logout',
        name: 'lgout',
        component: () => import('@/views/Logout.vue'),
        meta: {
          pageTitle: 'Logout',
          rule: 'editor',
        },
      },
      // Register
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/pages/register/Register.vue'),
        meta: {
          pageTitle: 'Register',
          rule: 'editor',
        },
      },
      // Forgot Password
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/pages/ForgotPassword.vue'),
        meta: {
          pageTitle: 'Forgot Password',
          rule: 'editor',
        },
      },
      // Reset Password
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/pages/ResetPassword.vue'),
        meta: {
          pageTitle: 'Reset Password',
          rule: 'editor',
        },
      },
      // Reset Password
      {
        path: '/password/reset',
        name: 'password-reset',
        component: () => import('@/views/pages/ResetPassword.vue'),
        meta: {
          pageTitle: 'Reset Password',
          rule: 'editor',
        },
      },
      // Verify User
      {
        path: '/user/verify',
        name: 'verify',
        component: () => import('@/views/pages/Verify.vue'),
        meta: {
          pageTitle: 'Verifying',
          rule: 'editor',
        },
      },
      // Lock Screen
      {
        path: '/lock-screen',
        name: 'lock-screen',
        component: () => import('@/views/pages/LockScreen.vue'),
        meta: {
          rule: 'editor',
        },
      },
      // Coming Soon
      {
        path: '/comingsoon',
        name: 'coming-soon',
        component: () => import('@/views/pages/ComingSoon.vue'),
        meta: {
          pageTitle: 'Coming Soon',
          rule: 'editor',
        },
      },
      // 404
      {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/pages/Error404.vue'),
        meta: {
          pageTitle: '404',
          rule: 'editor',
        },
      },
      // 500
      {
        path: '/error-500',
        name: 'error-500',
        component: () => import('@/views/pages/Error500.vue'),
        meta: {
          rule: 'editor',
        },
      },
      // Not Authorized
      {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/pages/NotAuthorized.vue'),
        meta: {
          pageTitle: 'Authorized',
          rule: 'editor',
        },
      },
      // Maintenance
      {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/views/pages/Maintenance.vue'),
        meta: {
          pageTitle: 'Maintenance',
          rule: 'editor',
        },
      },
      // Terms and Conditions
      {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: () => import('@/views/pages/TermsAndConditions.vue'),
        meta: {
          pageTitle: 'Terms And Conditions',
          rule: 'guest',
        },
      },
      // Privacy Policy
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/pages/PrivacyPolicy.vue'),
        meta: {
          pageTitle: 'Privacy Policy',
          rule: 'guest',
        },
      },
      {
        path: '/c/:eventLinkCode',
        name: 'EventLive',
        component: () => import('@/views/pages/events/Live.vue'),
        props: route => ({
          eventLinkCode: route.params.eventLinkCode,
        }),
        meta: {
          pageTitle: 'Event Live',
        },
      },
      // {
      //   path: '/c/:eventLinkCode/new',
      //   name: 'EventLive',
      //   component: () => import('@/views/pages/events/LiveNew.vue'),
      //   props: route => ({
      //     eventLinkCode: route.params.eventLinkCode,
      //   }),
      //   meta: {
      //     pageTitle: 'Event Live',
      //   },
      // },
      {
        path: '/c/:eventSlug/embed',
        name: 'EventLiveEmbed',
        component: () => import('@/views/EventLiveEmbed.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Event Live',
        },
      },
      {
        path: '/events/:eventSlug/display',
        name: 'EventDisplay',
        component: () => import('@/views/pages/events/EventDisplay.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Present:',
        },
      },
      {
        path: '/events/:eventSlug/display/clean',
        name: 'EventDisplayClean',
        component: () => import('@/views/pages/events/EventDisplayClean.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Present:',
        },
      },
      {
        path: '/c/:eventSlug/sidebar',
        name: 'EventDisplayWithSidebar',
        component: () => import('@/views/pages/events/EventDisplay.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Question:',
        },
      },
      {
        path: '/c/:eventSlug/quest',
        name: 'EventDisplayWithoutSidebar',
        component: () => import('@/views/pages/events/EventDisplay.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Question Sidebar:',
        },
      },
      {
        path: '/c/:eventSlug/highlight',
        name: 'EventDisplayHighlight',
        component: () => import('@/views/pages/events/EventDisplay.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Highlight:',
        },
      },
      {
        path: '/scanner',
        name: 'EventScanner',
        component: () => import('@/views/EventScanner.vue'),
        meta: {
          pageTitle: 'Event Scanner',
        },
      },
      {
        path: '/profile/:id',
        name: 'EventProfile',
        component: () => import('@/components/events/ExtendedProfile.vue'),
        meta: {
          pageTitle: 'Event Profile',
        },
      },
      {
        path: '/html',
        name: 'Iframe',
        component: () => import('@/views/LandingPage.vue'),
        meta: {
          pageTitle: 'Home',
        },
      },
      {
        path: '/c/:eventSlug/event',
        name: 'EventDisplayQR',
        component: () => import('@/views/pages/events/DisplayEventQR.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'QR Code:',
        },
      },
      {
        path: '/c/:eventSlug/control',
        name: 'EventDisplayControl',
        component: () => import('@/views/pages/events/EventDisplay.vue'),
        props: route => ({
          eventSlug: route.params.eventSlug,
        }),
        meta: {
          pageTitle: 'Moderator Control:',
        },
      },
      {
        path: '/upcoming-events',
        name: 'UpcomingEvents',
        component: () => import('@/views/UpcomingEvents.vue'),
        meta: {
          pageTitle: 'Upcoming Events',
          rule: 'guest',
        },
      },
      {
        path: '/event/:eventId/cancelSeat',
        name: 'EventCancelSeat',
        component: () => import('@/components/events/EventCancelSeat.vue'),
        props: route => ({
          eventId: route.params.eventId,
        }),
        meta: {
          pageTitle: 'Event Cancel Seat',
        },
      },
      {
        path: '/event/:eventId/confirmSeat',
        name: 'EventConfirmSeat',
        component: () => import('@/components/events/EventConfirmSeat.vue'),
        props: route => ({
          eventId: route.params.eventId,
        }),
        meta: {
          pageTitle: 'Confirm Reservation ',
        },
      },
      {
        path: '/code=:code',
        name: 'EventCode',
        component: () => import('@/components/events/EventByCode.vue'),
        props: route => ({
          code: route.params.code,
        }),
        meta: {
          pageTitle: 'Event By Code ',
        },
      },
    ],
  },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
